// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alert_alertContainer__pu6y4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  background: transparent;
  border: 1px solid #fff;
  font-weight: 700;
  right: 25px;
  max-width: 300px;
  height: 25px;
  color: #000;
  padding: 12.5px 40px;
  text-align: center;
  border-radius: 20px; }

.Alert_error__hSm7C {
  color: red; }

.Alert_success__pvk9G {
  color: greenyellow; }
`, "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB,EAAA;;AAGrB;EACE,UAAU,EAAA;;AAGZ;EACE,kBAAkB,EAAA","sourcesContent":[".alertContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  bottom: 50px;\n  background: transparent;\n  border: 1px solid #fff;\n  font-weight: 700;\n  right: 25px;\n  max-width: 300px;\n  height: 25px;\n  color: #000;\n  padding: 12.5px 40px;\n  text-align: center;\n  border-radius: 20px;\n}\n\n.error {\n  color: red;\n}\n\n.success {\n  color: greenyellow;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertContainer": `Alert_alertContainer__pu6y4`,
	"error": `Alert_error__hSm7C`,
	"success": `Alert_success__pvk9G`
};
export default ___CSS_LOADER_EXPORT___;
