// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrapper__SXTn0 {
  padding: 20px 25px; }

.Header_title__l\\+wMf {
  color: #fff;
  font-weight: 500;
  font-size: 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;;AAGpB;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":[".wrapper {\n  padding: 20px 25px;\n}\n\n.title {\n  color: #fff;\n  font-weight: 500;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Header_wrapper__SXTn0`,
	"title": `Header_title__l+wMf`
};
export default ___CSS_LOADER_EXPORT___;
