import React from 'react';
import classes from './Header.module.scss';

export default function Header() {
  return (
    <header className={classes.header}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>SpirometryAI</h3>
      </div>
    </header>
  );
}
