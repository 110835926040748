import React from 'react'
import classes from './Alert.module.scss';
import clsx from 'clsx';

interface IAlert {
  text: string,
  type?: 'error' | 'success'
}

export default function Alert({text = 'Error', type}: IAlert) {  
  return (
    <div className={classes.alertContainer}>
      <p className={clsx(classes.text, type === 'success' ? classes.success : classes.error)}>{text}</p>
    </div>
  )
}
