import React, { FormEvent, useState } from 'react';
import classes from './Main.module.scss';
import axios from '../../utils/axiosConfig';
import Alert from '../Alert';

export default function Main() {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [step, setStep] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<{type: "success" | "error", text: string}>({
    type: "success",
    text: ''
  });

  const handleOpenALert = (type: "success" | "error", text: string) => {
    setIsOpen(true);

    setAlertState({
      type,
      text,
    })

    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  }

  const [data, setData] = useState<{
    score: string,
    verdict: string,
    status_code: string,
  }>({
    score: '',
    verdict: '',
    status_code: '',
  });

  // useEffect(() => {
  //   console.log(selectedFiles);
  //   if (selectedFiles?.length === 2 && selectedFiles[0]?.name && selectedFiles[1]?.name) {
  //     setTimeout(() => {
  //       postImages(selectedFiles[0], selectedFiles[1]);
  //       setStep(1);
  //     }, 1500);
  //   }
  // }, [selectedFiles, selectedFiles?.length])

  const handleAnalyzeBtn = () => {
    if(selectedFile) {
      postImages(selectedFile);
    }
    setStep(1);
  };

  
  const postImages = async (file: File) => {
    try {

      const formData = new FormData();
      formData.append("file0", file);
      const result = await axios.post('/images', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

      if (result) {
        setStep(2);
        const {data} = result;
        setData(data)
      }
    
      setSelectedFile(undefined);
      return result;
    } catch (error) {
      setTimeout(() =>  {
        handleOpenALert("error", 'Something went wrong with Server')
        setStep(0);
      }, 2000)
      setSelectedFile(undefined);
      return error;
    }
  };

  // const handleDrop = (event: any) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files[0];
  //   console.log(file);
  //   const id = (event.currentTarget as HTMLLabelElement).id;
  //   console.log(id);
  //   if(id.includes('1')) {
  //     const defaultFiles = [file, selectedFiles[1]];
  //     setSelectedFiles(defaultFiles);
  //   } else if (id.includes('2')) {
  //     const defaultFiles = [selectedFiles[0], file];
  //     setSelectedFiles(defaultFiles);
  //   }
  //   handleOpenALert("success", `Image ${file.name} was successfully uploaded`)
  // };

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const file = (event.target as HTMLFormElement).files[0];
      const id = (event.target as HTMLFormElement).id;
      if(id?.includes('1')) {
        setSelectedFile(file);
      }
      handleOpenALert("success", `Image ${file.name} was successfully uploaded`)
      return;
    } catch (error) {
      handleOpenALert("error", `Some error happened while processing your file`)
      return;
    }
  };

  return (
    <main className={classes.main}>
      {isOpen && <Alert text={alertState.text} type={alertState.type} />}
      <div className={classes.wrapper}>
        {step === 0 && (
          <section className={classes.section}>
            <h1 className={classes.title}>
              Analyze spirometry maneuvers
              <span className={classes.aiSpan}> using AI</span>
            </h1>
            <p className={classes.subtitle}>
              For fast efficient work with files
            </p>
            <div className={classes.uploadCardsWrapper}>
              <form className={classes.form} onChange={submitForm} id="form" encType='multipart/form-data'>
                <div className={classes.uploadCardFirst}>
                  <input
                    type='file'
                    accept="image/png, image/jpg, image/bmp, image/tip"
                    className={classes.inputFile}
                    id='file_1'
                  />
                  <div className={classes.cardBorder}>
                    <label className={classes.uploadLabel} 
                    htmlFor='file_1'
                    id='label_1'         
                    // onDrop={handleDrop}
                    // onDragOver={(event) => event.preventDefault()}
                    >
                      <h5 className={classes.heading}>
                      Step 1: upload here Flow/Volume chart from your spirometry software                      
                      </h5>
                      {/* {selectedFiles && selectedFiles[0] &&  <p className={classes.fileName}>{selectedFiles[0]?.name}</p>} */}
                      {selectedFile && <p className={classes.uploadedLabel}>Uploaded</p>}
                      <div className={classes.exampleFirst} />
                    </label>
                  </div>
                </div>
              </form>
              <button disabled={!selectedFile} className={classes.analyzeBtn} onClick={handleAnalyzeBtn}>
                Analyze
              </button>
              <p className={classes.guidline}>
              Upload a single curve image, with no grid or other labels for the best performance of the demo
              </p>
            </div>
          </section>
        )}
        {step === 1 && (
          <section className={classes.secondSection}>
            <h2 className={classes.title}>
              Spirometry <span>maneuver analysis...</span>
            </h2>
            <p className={classes.subtitle}>
              For fast efficient work with files
            </p>
            <div className={classes.loader} onClick={() => setStep(2)} />
          </section>
        )}
        {step === 2 && (
          <>
            <section className={classes.thirdSection}>
              <h2 className={classes.title}>Spirometry analyzed!</h2>{' '}
              {/* <button className={classes.downloadBtn}>
                <img src={DownloadImage} alt='Download' />
                <span>Download acceptability report</span>
              </button> */}
              <div className={classes.resultContainer}>
                <span>Score: {data.score}</span>
                <p>Verdict: {data.verdict === 'Unacceptable' ? <span className={classes.notAcceptable}>{data.verdict}</span> : <span className={classes.acceptable}>{data.verdict}</span>}</p>
              </div>
              <button className={classes.downloadBtn} onClick={() => setStep(0)}>
                Analyze a new spirometry
              </button>
              <p className={classes.disclaimer}>
              This demo is a morphology-based AI-powered analysis of the spirometry maneuver.More insight and deeper analysis can be achieved by integrating numerical data from your spirometry software 
              </p>
            </section>
          </>
        )}
      </div>
    </main>
  );
}
